@import url(https://rsms.me/inter/inter.css);
html,
body,
#root {
  position: relative;
  /* margin: 0;
  padding: 0; */
  overflow: hidden;
  outline: none;
  width: 100vw;
  height: 100vh;
  /* background: red; */
}

body {
  background: #f2f2f2;
  font-family: "Inter var", sans-serif;
  display: flex;
  justify-content: center;
}

/* h1 {
  position: absolute;
  top: 43px;
  left: 140px;
  padding: 0;
  margin: 40px;
  font-size: 5em;
  font-weight: 600;
  line-height: 0.7em;
  letter-spacing: -6px;
  color: #272730;
} */

.picker {
  /* left: 70px; */
  top: 30px;
  right: 10px;
  width: 150px !important;
  height: 100px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
}

/* .pik {
  display: flex;
  flex-direction: row;
} */
.header {
  position: absolute;
  width: 100%;
  /* background: blue; */
  display: flex;
  justify-content: space-between;
}

.footer {
  position: absolute !important;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  bottom: 0px;
  color: #050505;
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  width: 96%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 200px;
  margin: 20px;
}

.cartBtn {
  background: #0040ff;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 20px 60px;
  /* width: 100%; */
  cursor: pointer;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.3s;
}

.cartBtn:hover {
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.leftSection {
  width: 100%;
}

.rightSection {
  width: 100%;
}

.itemsList {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.colorView {
  height: 30px;
  width: 30px;
  /* background: red; */
  border-radius: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.selectedItemName {
  font-weight: 700;
}

